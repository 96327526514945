/** @format */

import { createStore } from "solid-js/store";
import { CostType } from "../views/home/home.type";
import { AcrRecord, UserInfo } from "./global.data.type";
import {
  getLocOutType,
  getLocPutType,
  getLocUserInfo,
  getLocRecordList,
} from "../utils/utils.localstroage";

//console.log(1);

/**
 * 所有支出分类
 */
const [typeData, setTypeData] = createStore<CostType[]>(
  getLocPutType() || []
  //   []
);

/**
 * 所有收入分类
 */
const [outTypeData, setOutTypeData] = createStore<CostType[]>(
  getLocOutType() || []
  //   []
);

/**
 * 当前选中的一级分类数据
 */
interface SelOneType {
  val: CostType;
  inx: number;
}
/**
 * 选中的一级分类
 */
const [selOneType, setSelOneType] = createStore<SelOneType>({
  val: {
    id: 0,
    name: "",
    type: 1,
    children: [],
  },
  inx: -1,
});

/**
 * app启动数据、全局数据
 */
const [gld, setGld] = createStore<any>({
  // sbh: 0,
  // sbh: 37,
  sbh: 20,
  romVersion: "V816",
  osVersion: "15",
  romName: "MUl",
  language: "zh",
  brand: "xiaomi",
  model: "24129PN74C",
  theme: "light",
  // theme: "dark",
  osName: "android",
});
//console.log("11111", gld);

/**
 * 用户信息
 */
const [userInfo, setUserInfo] = createStore<UserInfo>(
  getLocUserInfo() || ({ monthTypeMoney: {} } as UserInfo)
);
// //console.log("本地用户信息", userInfo);

/**
 * 账单记录
 */
const [recordHistory, setRecordHistory] = createStore<AcrRecord[]>(
  getLocRecordList() || []
);




export {
  typeData,
  setTypeData,
  gld,
  setGld,
  userInfo,
  setUserInfo,
  outTypeData,
  setOutTypeData,
  // selType,
  // setSelType,
  selOneType,
  setSelOneType,
  recordHistory,
  setRecordHistory,
};
