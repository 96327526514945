import { Component, JSX, Show } from "solid-js";
import { gld } from "../../store/global.data";
import "./pageHeader.scss";
import { useNavigate } from "@solidjs/router";

export interface PageHeaderType {
  /**
   * 是否固定
   */
  position?: "sticky" | "relative";
  /**
   * 添加类名
   */
  addClass?: string;
  /**
   * 标题
   */
  title?: string;
  /**
   * 子元素
   */
  children?: {
    /**
     * 中间元素
     */
    content?: JSX.Element;
    /**
     * 底部元素
     */
    buttom?: JSX.Element;
  };
}
export const PageHeader: Component<PageHeaderType> = (props) => {
  const router = useNavigate();

  function goBack() {
    // console.log("???");
    router(-1);
  }
  return (
    <>
      {/* height:calc(2rem + ${gld.sbh}px + var(--sm-sp)); */}
      <div
        class={`p_h ${!props.position ? "sticky" : ""} ${
          props.addClass ? props.addClass : ""
        }`}
        style={`padding-top:${gld.sbh}px;`}
      >
        <div class="p_h_c">
          <button class="btn btn-sm ret_btn" onclick={goBack}>{`<`}</button>
          <Show when={props.title}>
            <span class="tit">{props.title}</span>
          </Show>
          <div class="s_c">{props.children?.content}</div>
        </div>

        {props.children?.buttom}
      </div>
    </>
  );
};
