import { createSignal, Show } from "solid-js";
import { PageHeader } from "../../components/pageHeader/pageHeader";
import { appLogOut } from "../../logic/app.logic";
import { userInfo } from "../../store/global.data";
import { appBarck } from "../../utils/utils.router";
import "./setting.scss";
import { createStore } from "solid-js/store";
import { UserInfo } from "../../store/global.data.type";
import { getNewObject } from "../../utils/utils.object";
import {
  getLocDeveloper,
  setLocDeveloper,
  setLocUserInfo,
} from "../../utils/utils.localstroage";
import vConsole from "vconsole";

export function Setting() {
  function outLogin() {
    appLogOut();
    appBarck();
  }
  const [settingInfo, SettingInfo] = createStore<UserInfo>(
    getNewObject(userInfo)
  );

  const [console, setConsole] = createSignal(getLocDeveloper() ? true : false);
  if (console()) {
    new vConsole();
  }

  return (
    <>
      <PageHeader></PageHeader>
      <div class="set_v">
        {/* <label class="label cursor-pointer">
          <span class="label-text">资产统计</span>
          <input
            type="checkbox"
            class="toggle toggle-primary"
            checked={settingInfo.ps ? true : false}
            onChange={(event: Event) => {
              SettingInfo(
                "ps",
                (event.target as HTMLInputElement).checked ? 1 : 0
              );
              setLocUserInfo(settingInfo);
            }}
          />
        </label> */}
        <Show when={userInfo.id == 1}>
          <label class="label cursor-pointer">
            <span class="label-text">F12</span>
            <input
              type="checkbox"
              class="toggle toggle-primary"
              checked={console()}
              onChange={(event: Event) => {
                setConsole((event.target as HTMLInputElement).checked);
                setLocDeveloper(console());
                if (console()) {
                  new vConsole();
                }
              }}
            />
          </label>
        </Show>
        <ul class="menu bg-base-100 rounded-box w-56">
          <li>
            <a>Item 1</a>
          </li>
          <li>
            <a>Item 2</a>
          </li>
          <li>
            <a>Item 3</a>
          </li>
        </ul>
        <button class="btn btn-primary" onclick={outLogin}>
          退出登录
        </button>
      </div>
    </>
  );
}
