import { createSignal, For, onMount, Show } from "solid-js";
import { PageHeader } from "../../components/pageHeader/pageHeader";
import "./billing.scss";
import { userInfo } from "../../store/global.data";
import { AcrRecord, Wallet } from "../../store/global.data.type";
import { createStore, produce } from "solid-js/store";
import { RecordList } from "../record/recordList";
import { domById } from "../../utils/utils.html";
import { service } from "../../utils/utils.request";

/**
 * 账单明细
 */
export function Billing() {
  // const [timeOption, setTimeOption] = createStore<any[]>([
  //   {
  //     lab: "所有时间",
  //     val: "1",
  //   },
  // ]);
  const [wName, setWName] = createSignal<string>("全部钱包");
  // const [tName, setTName] = createSignal<string>(timeOption[0].lab);

  const [typeOption, setTypeOption] = createStore<any[]>([
    // {
    //   lab: "全部账单",
    //   val: 0,
    // },
    {
      lab: "支出",
      val: 1,
    },
    {
      lab: "收入",
      val: 2,
    },
  ]);
  let allTypeName = "全部账单";
  const [typeName, setTypeName] = createSignal<string>(allTypeName);

  /**
   * 加载中？？
   */
  const [noMore, setNoMore] = createSignal<boolean>(false);

  /**
   * 查询条件
   */
  const query: any = {
    wId: 0,
    time: "1",
    skip: 1,
    take: 20,
  };

  /**
   * 数据列表
   */
  const [recordList, setRecordList] = createStore<AcrRecord[]>([]);

  /**
   * 钱包菜单
   */
  let menuWallet: any = null;
  /**
   * 时间菜单
   */
  // let menuTime: any = null;

  /**
   * 加载中
   */
  let loading = false;

  /**
   * 获取记录
   */
  function getRecordHistory(add: boolean = false) {
    if (!add) {
      query.skip = 1;
      setRecordList([]);
    }
    setNoMore(false);
    loading = true;
    service({
      url: "/ab/g-ar",
      method: "GET",
      cache: true,
      params: {
        p: JSON.stringify(query),
      },
    }).then((res) => {
      if (res.status == 200) {
        let data = JSON.parse(res.data) as Array<AcrRecord>;
        console.log("当日记录更新", data);
        if (data.length == 0) {
          setNoMore(true);
        } else {
          setNoMore(false);
        }
        if (add) {
          setRecordList(
            produce((val) => {
              val.push(...data);
            })
          );
        } else {
          setRecordList(data);
        }
        // setTimeout(() => {

        // }, 3000);
      }
      loading = false;
    });
  }
  getRecordHistory();

  // function menuTimeClick(ti: any) {
  //   menuTime?.removeAttribute("open");
  //   if (ti == tName()) {
  //     return;
  //   }
  //   setTName(ti.lab);
  // }

  function handleScroll() {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    if (documentHeight - scrollTop - windowHeight <= 100) {
      // Trigger callback method
      if (!noMore() && !loading) {
        query.skip += 1;
        getRecordHistory(true);
      }
    }
  }

  onMount(() => {
    window.addEventListener("scroll", handleScroll);
    menuWallet = domById("menuWallet");
    // menuTime = domById("menuTime");
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <>
      <PageHeader addClass={`bg-base-200`} title="账单">
        {{
          content: (
            <>
              <button class="btn btn-sm t_btn btn-primary">统计</button>
            </>
          ),
          buttom: (
            <>
              <ul class="menu  menu_v menu menu-horizontal bg-base-200 rounded-box">
                <li class="m_it">
                  <details id="menuType">
                    <summary>{typeName()}</summary>
                    <ul>
                      <For each={typeOption}>
                        {(ti: any) => {
                          return (
                            <>
                              <li>
                                <button
                                  class="wallet_a"
                                  on:click={(event) => {
                                    if (typeName() == ti.lab) {
                                      (
                                        event.target as HTMLButtonElement
                                      ).blur();
                                      setTypeName(allTypeName);
                                      delete query.type;
                                    } else {
                                      setTypeName(ti.lab);
                                      if (ti.val != 0) {
                                        query.type = ti.val;
                                      }
                                    }
                                    getRecordHistory();
                                  }}
                                >
                                  {ti.lab}
                                </button>
                              </li>
                            </>
                          );
                        }}
                      </For>
                    </ul>
                  </details>
                </li>
                <Show when={userInfo.ps && userInfo.walletList.length > 0}>
                  <li class="m_it">
                    <details id="menuWallet">
                      <summary>{wName()}</summary>
                      <ul>
                        <For each={userInfo.walletList}>
                          {(item: Wallet) => {
                            return (
                              <>
                                <li>
                                  <button
                                    class="wallet_a"
                                    onclick={(element) => {
                                      if (query.wId == item.id) {
                                        query.wId = 0;
                                        setWName("全部钱包");
                                        getRecordHistory();
                                        (
                                          element.target as HTMLButtonElement
                                        ).blur();
                                      } else {
                                        query.wId = item.id;
                                        setWName(item.name);
                                        getRecordHistory();
                                      }
                                    }}
                                  >
                                    {item.name}
                                  </button>
                                </li>
                              </>
                            );
                          }}
                        </For>
                      </ul>
                    </details>
                  </li>
                </Show>
              </ul>
            </>
          ),
        }}
      </PageHeader>
      <RecordList records={recordList} noMore={noMore()}></RecordList>
    </>
  );
}
