/** @format */

import "./recordList.scss";
import { Component, For, onCleanup, onMount, Show } from "solid-js";
import { allTypeMap } from "../../store/global.filter";
import { AcrRecord } from "../../store/global.data.type";
import { formattedDate } from "../../utils/utils.time";

interface RecordListProps {
  /**
   * 记录列表
   */
  records: Array<AcrRecord>;
  /**
   * 没更多数据
   */
  noMore?: boolean;
  /**
   * 不显示状态
   */
  noStatus?: boolean;
}
export const RecordList: Component<RecordListProps> = (props) => {
  return (
    <>
      <div class="rl_v">
        <For
          each={props.records}
          fallback={
            <>
              {() => {
                if (!props.noStatus) {
                  if (props.noMore) {
                    return <p>无数据</p>;
                  } else {
                    return <p>加载中...</p>;
                  }
                }
              }}
            </>
          }
        >
          {(item: AcrRecord) => {
            return (
              <>
                <div class="rv_i">
                  <span>
                    {allTypeMap[item.twoId]?.before ||
                      allTypeMap[item.oneId]?.name}
                    {allTypeMap[item.twoId]?.name}&nbsp;
                    {item.remark}
                  </span>
                  {/* 如果是负债借入 */}
                  <div class="rv_i_r">
                    {item.type == 1 && item.loan ? (
                      // 如果是负债
                      <span class="loan">-{item.money.toString()}</span>
                    ) : item.type == 1 ? (
                      // 付过是支出
                      <span class="col_put">-{item.money.toString()}</span>
                    ) : item.type == 2 ? (
                      <span class="col_out">+{item.money.toString()}</span>
                    ) : (
                      ""
                    )}
                    <span class="tim">{formattedDate(item.creTime)}</span>
                  </div>
                </div>
              </>
            );
          }}
        </For>
      </div>
      
    </>
  );
};
